<template>
  <div>
    <el-dialog title="添加收藏标签"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <div style="padding:10px 0;">
        可以添加多个标签，标签以空格分开。不需要标签的直接点击"确定"。
      </div>
      <el-row :gutter="10">
        <el-col :span="18">
          <el-input clearable
                    placeholder="请输入"
                    v-model="tags"></el-input>
        </el-col>
      </el-row>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary"
                   @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      tags: '',
      question_id: ''
    }
  },
  methods: {
    handleClose () {
      this.tags = ''
      this.dialogVisible = false
    },
    save () {
      this.$http({
        url: '/api/v1/papers/favorite',
        method: 'post',
        data: {
          question_id: this.question_id,
          tags: this.tags
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        this.$parent.getInfo()
        this.handleClose()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>